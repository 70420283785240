import React, { useEffect } from "react";
import "./Chatango.css";

function Chatango() {
  useEffect(() => {
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src = "//st.chatango.com/js/gz/emb.js";
    script.async = true;
    script.dataset.cfasync = false;
    script.id = "cid0020000253216372902";
    script.style = "width: 300px;height: 420px;";
    script.innerHTML = `{"handle":"faknul","arch":"js","styles":{"a":"33ff33","b":100,"c":"6600cc","d":"6600cc","e":"000000","g":"ffffff","k":"33ff33","l":"3333ff","m":"33ff33","p":"10","q":"6600cc","r":100,"usricon":0,"sbc":"3366ff","surl":0,"allowpm":0,"cnrs":"0.35","fwtickm":1}}`;
    document.getElementById("chatango").appendChild(script);

    return () => {
      document.getElementById("chatango").removeChild(script);
      var iframes = document.querySelectorAll("iframe");
      for (var i = 0; i < iframes.length; i++) {
        iframes[i].parentNode.removeChild(iframes[i]);
      }
    };
  }, []);
  return (
    <div>
      <div id="chatango"></div>
    </div>
  );
}

export default Chatango;
