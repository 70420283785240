import React, { useEffect, useState } from "react";
import "./App.css";
import { BrowserRouter as Router, Route } from "react-router-dom";
import { Provider } from "react-redux";
import store from "./store";
import Home from "./pages/Home.js";
import About from "./pages/About.js";
import Forum from "./pages/Forum.js";
import Archive from "./pages/Archive.js";
import NavBar from "./components/NavBar";
import SideBanner from "./components/sideBanner.js";
import logo from "./images/logo-no-background.png";
import axios from "axios";

function App() {
  const [events, setEvents] = useState([]);
  const [futureEvents, setFutureEvents] = useState([]);
  const [aboutInfo, setAboutInfo] = useState("");

  useEffect(() => {
    const getEvents = async () => {
      const res = await axios.get(
        "https://fakultaetnull.herokuapp.com/api/events"
      );
      let allEvents = res.data.events;
      setEvents(allEvents);
      let today = new Date();
      today.setDate(today.getDate() - 1);
      let updatedFutureEvents = allEvents.filter(
        (event) =>
          Date.parse(event.dateAndTime[event.dateAndTime.length - 1].date) >=
          Date.parse(today)
      );

      setFutureEvents(updatedFutureEvents);
    };
    getEvents();
  }, []);

  useEffect(() => {
    axios
      .get("https://fakultaetnull.herokuapp.com/api/about")
      .then((aboutImage) => {
        let bannerImageBase64 = new Buffer(
          aboutImage.data.img.data.data
        ).toString("base64");
        setAboutInfo(bannerImageBase64);
      });
  }, []);

  return (
    <Provider store={store}>
      <div className="background">
        <div className="appBoard">
          <div>
            <img
              className="logo-image"
              src={logo}
              alt="Fakultaet Null Logo"
            ></img>
            {futureEvents && <SideBanner nextEvent={futureEvents[0]} />}
          </div>
          <Router>
            <Route exact path="/" render={() => <Home />} />
            <Route
              exact
              path="/about"
              render={() => <About aboutInfo={aboutInfo} />}
            />
            <Route exact path="/forum" render={() => <Forum />} />
            <Route
              exact
              path="/archive"
              render={() => <Archive events={events} />}
            />
            <NavBar events={futureEvents} />
          </Router>
        </div>
      </div>
    </Provider>
  );
}

export default App;
