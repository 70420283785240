import React from "react";

function Forum() {
  return (
    <>
      <div>
        We are still currently working on embedding our wonderful new forum!
        {/* But in the meantime, select:{" "}
        <a href="https://discourse.interflugs.de/" target="_blank">
          Interflugs/ Fakultat Null Forum
        </a>{" "}
        to check it out */}
      </div>
    </>
  );
}

export default Forum;
