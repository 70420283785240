import React from "react";
import "./SideBanner.css";
import bannerTemplate from "../images/sideBanner.png";

function SideBanner({ nextEvent }) {
  const convertDate = (dateISO) => {
    let date = new Date(dateISO);
    let year = date.getFullYear();
    let monthIndex = date.getMonth();
    let dt = date.getDate();
    let months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "June",
      "July",
      "Aug",
      "Sept",
      "Oct",
      "Nov",
      "Dec",
    ];

    if (dt < 10) {
      dt = "0" + dt;
    }

    return dt + " " + months[monthIndex] + " " + year;
  };

  const convertImage = (data) => {
    let bannerImageBase64 = new Buffer(data).toString("base64");
    return bannerImageBase64;
  };

  return (
    <div className="sideBanner">
      {nextEvent && (
        <a
          target="_blank"
          rel="noopener noreferrer"
          href={nextEvent.links[0].link}
        >
          <div className="sideBannerInfo">
            <div className="sideBannerTitle">
              Upcoming Event: {nextEvent.title}
            </div>

            {nextEvent.img && (
              <img
                className="sideBannerEventImage"
                alt={nextEvent.title}
                src={`data:image/jpg;base64,${convertImage(
                  nextEvent.img.data
                )}`}
              />
            )}
            {nextEvent.dateAndTime.map((schedule) => (
              <li key={schedule._id}>
                <div className="sideBannerDate">
                  {convertDate(schedule.date)}
                  <br></br>
                  {schedule.startTime} {schedule.endTime && "-"}{" "}
                  {schedule.endTime}
                </div>
              </li>
            ))}
            <p className="sideBannerLocation">{nextEvent.location}</p>
            <img
              className="sideBannerImage"
              alt="upcoming event"
              src={bannerTemplate}
            />
          </div>
        </a>
      )}
    </div>
  );
}

export default SideBanner;
