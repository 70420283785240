import React, { useEffect, useState } from "react";
import Events from "../components/Events";
import "./Archive.css";
function Archive({ events }) {
  const [pastEvents, setPastEvents] = useState([]);
  const noEventsMessage = "event archive loading";

  useEffect(() => {
    let today = new Date();
    let updatedPastEvents = events
      .filter(
        (event) =>
          Date.parse(event.dateAndTime[event.dateAndTime.length - 1].date) <
          Date.parse(today)
      )
      .reverse();

    setPastEvents(updatedPastEvents);
  }, [events]);

  return (
    <div>
      <div className="archive_title"> Event Archive: </div>
      <div className="eventArchiveBoard">
        <Events events={pastEvents} noEventsMessage={noEventsMessage} />
      </div>
    </div>
  );
}

export default Archive;
