import React from "react";
import Chatango from "../components/Chatango";
import "./Home.css";
import { TwitchPlayer } from "react-twitch-embed";

function Home() {
  return (
    <>
      <div className="home-main">
        <TwitchPlayer
          id="twitchFrame"
          channel="interflugs"
          className="twitchFrame"
          title="Interflugs Twitch Stream"
          src="https://player.twitch.tv/?channel=interflugs&amp;parent=fakultaetnull.org"
          parent={["fakultaetnull.org"]}
          frameborder="4px"
          framecolor="green"
          allowfullscreen="true"
          scrolling="no"
          height="500"
          width="600"
        />

        <Chatango />
        <div className="loginLink animate__pulse">
          <a className="loginlinkA" href="https://fakultaetnull.herokuapp.com">
            Log In
          </a>
        </div>
      </div>
    </>
  );
}

export default Home;
