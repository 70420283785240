import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import "./Events.css";

function Events({ events, toggle, noEventsMessage }) {
  const [noEvents, setNoEvents] = useState(false);
  const [eventsWithDetail, setEventsWithDetail] = useState([]);
  let loading = useSelector((state) => state.events && state.events.loading);

  useEffect(() => {
    if (events && events.length === 0) {
      setNoEvents(true);
    } else {
      setNoEvents(false);
      setEventsWithDetail(events);
    }
  }, [events.length]);

  const convertDate = (dateISO) => {
    let date = new Date(dateISO);
    let year = date.getFullYear();
    let monthIndex = date.getMonth();
    let dt = date.getDate();
    let months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "June",
      "July",
      "Aug",
      "Sept",
      "Oct",
      "Nov",
      "Dec",
    ];

    if (dt < 10) {
      dt = "0" + dt;
    }

    return dt + " " + months[monthIndex] + " " + year;
  };

  const convertImage = (data) => {
    let bannerImageBase64 = new Buffer(data).toString("base64");
    return bannerImageBase64;
  };

  const toggleViewDetails = (id) => {
    let updatedEvents = eventsWithDetail.map((event) => {
      return event._id === id
        ? { ...event, viewDetails: !event.viewDetails }
        : event;
    });
    setEventsWithDetail(updatedEvents);
  };

  return (
    <div>
      {loading && <div className="loading">loading</div>}
      {toggle && (
        <div onClick={toggle} className="closeEvents">
          X
        </div>
      )}
      <header className="eventsHeader">
        <p>what</p>
        <p>where</p>
        <p>when</p>
      </header>
      {noEvents && <p className="events__no-events">{noEventsMessage}</p>}
      {eventsWithDetail &&
        eventsWithDetail.map((event) => (
          <div className="eventSingleCard" key={event._id}>
            <div
              onClick={() => toggleViewDetails(event._id)}
              className="topRow"
            >
              <div className="eventTitle">{event.title}</div>
              <div className="eventLocation">{event.location}</div>
              <div className="eventDate">
                {event.dateAndTime.map((schedule) => (
                  <li key={schedule._id}>
                    <div>
                      {convertDate(schedule.date)} - {schedule.startTime}{" "}
                      {schedule.endTime && "-"} {schedule.endTime}
                    </div>
                  </li>
                ))}
              </div>
            </div>
            <div>
              {event.viewDetails && (
                <div>
                  <div className="imageDescription">
                    {event.img && (
                      <img
                        className="eventImage"
                        alt={event.title}
                        src={`data:image/jpg;base64,${convertImage(
                          event.img.data
                        )}`}
                      />
                    )}
                    {event.description && (
                      <div className="eventDescription">
                        {event.description}
                      </div>
                    )}
                  </div>
                  <div className="linkList">
                    <div className="eventLink">Link/s:</div>
                    {event.links &&
                      event.links.map((link) => (
                        <li className="eventLink" key={link._id}>
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href={link.link}
                          >
                            {link.link}
                          </a>
                        </li>
                      ))}
                  </div>
                </div>
              )}
            </div>
          </div>
        ))}
    </div>
  );
}

export default Events;
