import {
  GET_EVENTS,
  ADD_EVENT,
  DELETE_EVENT,
  EVENTS_LOADING,
} from "../actions/types";

const initialState = {
  eventList: [],
  eventArchive: [],
  loading: false,
  success: true,
};

export default function (state = initialState, action) {
  switch (action.type) {
    case GET_EVENTS:
      return {
        ...state,
        eventList: action.payload,
        loading: false,
      };
    case DELETE_EVENT:
      return {
        ...state,
        eventList: state.eventList.filter(
          (event) => event._id !== action.payload
        ),
        loading: false,
      };
    case ADD_EVENT:
      return {
        ...state,
        eventList: [...state.eventList, action.payload],
        loading: false,
      };
    case EVENTS_LOADING:
      return {
        ...state,
        loading: true,
      };
    default:
      return {
        ...state,
      };
  }
}
