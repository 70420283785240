import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import Events from "../components/Events";
import "./NavBar.css";

function NavBar({ events }) {
  const [eventsVisible, setEventsVisible] = useState(false);
  const noEventsMessage = `There are no upcoming events at this time. Write us with any ideas at freieklasse@interflugs.de`;

  const handleEventsComponent = () => {
    setEventsVisible(!eventsVisible);
  };
  return (
    <div className="navBar">
      <NavLink
        exact
        activeClassName="navLink--active"
        className="navLink"
        to={"/"}
      >
        Home
      </NavLink>
      <NavLink
        activeClassName="navLink--active"
        className="navLink"
        to={"/about"}
      >
        About
      </NavLink>
      <NavLink
        activeClassName="navLink--active"
        className="navLink"
        to={"/forum"}
      >
        Forum
      </NavLink>
      <NavLink
        exact
        activeClassName="navLink--active"
        className="navLink"
        to={"/archive"}
      >
        Archive
      </NavLink>
      <button onClick={handleEventsComponent} className="eventNavLink">
        Events
      </button>
      {eventsVisible && (
        <div className="navBar__eventBoard__background">
          <div className="eventBoard">
            <Events
              events={events}
              toggle={handleEventsComponent}
              noEventsMessage={noEventsMessage}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default NavBar;
