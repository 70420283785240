import React from "react";
function About({ aboutInfo }) {
  return (
    <>
      <div className="aboutText">
        {aboutInfo && (
          <img
            className="aboutInfoImage"
            alt="about fakultaetnull"
            src={`data:image/jpg;base64,${aboutInfo}`}
          />
        )}
        <a className="externalLinks" href="https://www.interflugs.de/">
          C: Official Site
        </a>
        <br />
        <a
          className="externalLinks"
          href="https://www.instagram.com/_interflugs_/"
        >
          C: Instagram
        </a>
        <br />
        <a className="externalLinks" href="https://twitter.com/interflugs">
          C: Twitter
        </a>
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <br />
        <a className="externalLinks" href="https://fakultaetnull.herokuapp.com">
          C: Log In
        </a>
      </div>
    </>
  );
}

export default About;
